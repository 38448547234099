<!-- Fixed navbar -->
<nav class="navbar navbar-expand-lg navbar-dark bg-primary fixed-top">
  <div class="container">
    <span class="navbar-brand">{{ title }}</span>
  </div>
</nav>

<div class="container main-container">
  <app-conclave-settings></app-conclave-settings>
  <h2>Setup quick reference</h2>
  <table class="table table-sm">
    <thead class="thead-dark">
      <tr>
        <th scope="col">Players</th>
        <th scope="col">VPs</th>
        <th scope="col">L1 Advancements</th>
      </tr>
    </thead>
    <tbody>
      <tr>
        <th scope="row">2</th>
        <td>23</td>
        <td>12</td>
      </tr>
      <tr>
        <th scope="row">3</th>
        <td>28</td>
        <td>15</td>
      </tr>
      <tr>
        <th scope="row">4</th>
        <td>33</td>
        <td>18</td>
      </tr>
      <tr>
        <th scope="row">5</th>
        <td>40</td>
        <td>24</td>
      </tr>
      <tr>
        <th scope="row">6</th>
        <td>47</td>
        <td>30</td>
      </tr>
    </tbody>
  </table>
</div> <!-- /container -->

<a class="github-fork-ribbon right-top d-none d-md-block" href="https://github.com/kbarnes3/MysticVale" data-ribbon="Fork me on GitHub" title="Fork me on GitHub">Fork me on GitHub</a>
<footer class="footer" role="contentinfo">
  <div class="container">
    <p>&copy; 2018-2023 Kevin Barnes</p>
    <p>Built from version {{ gitVersion }}</p>
  </div>
</footer>
